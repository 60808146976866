import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import { Link } from "gatsby"
import termsImg from "../assets//images/terms-of-service.png"
import Terms from "../markdown/website_tos.md"

const TermsOfService = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Terms of Service"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Terms of Service"
      />
      <section className="terms-of-service-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="terms-of-service-content">
                <img src={termsImg} alt="tt" />
                <Terms />
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <aside className="widget-area">
                <div className="widget widget_insight">
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li className="active">
                      <Link to="#">Terms of Service</Link>
                    </li>
                  </ul>
                </div>

                {/* <div className="widget widget_recent_courses">
                  <h3 className="widget-title">Recent Courses</h3>

                  <article className="item">
                    <Link to="#" className="thumb">
                      <span className="fullimage cover bg1" role="img"></span>
                    </Link>
                    <div className="info">
                      <span>$49.00</span>
                      <h4 className="title usmall">
                        <Link to="#">
                          The Data Science Course 2020: Complete Data Science
                          Bootcamp
                        </Link>
                      </h4>
                    </div>
                    <div className="clear"></div>
                  </article>

                  <article className="item">
                    <Link to="#" className="thumb">
                      <span className="fullimage cover bg2" role="img"></span>
                    </Link>
                    <div className="info">
                      <span>$59.00</span>
                      <h4 className="title usmall">
                        <Link to="#">
                          Java Programming MasterclassName for Software
                          Developers
                        </Link>
                      </h4>
                    </div>
                    <div className="clear"></div>
                  </article>

                  <article className="item">
                    <Link to="#" className="thumb">
                      <span className="fullimage cover bg3" role="img"></span>
                    </Link>
                    <div className="info">
                      <span>$69.00</span>
                      <h4 className="title usmall">
                        <Link to="#">
                          Deep Learning A-Z™: Hands-On Artificial Neural
                          Networks
                        </Link>
                      </h4>
                    </div>
                    <div className="clear"></div>
                  </article>
                </div> */}
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default TermsOfService
